import React from "react";

function BrsrOtherProducts({ otherProductsList }) {
  return (
    <div className="brsr-other-products-outer-container">
      <div className="brsr-other-products-heading-cover">
        <p className="brsr-other-products-heading">
          Other Products from Updapt ESG Suite.
        </p>
      </div>

      <div className="brsr-other-products-card-container">
        {otherProductsList?.map((product) => {
          return (
            <div className="brsr-other-products-card" key={product.id}>
              <p className="brsr-other-products-logo">
                <img src={product?.productLogo} alt={product.name} />
              </p>
              <div className="brsr-other-products-content-cover">
                <a href={product.link}>
                  {" "}
                  <p className="brsr-other-products-name">{product?.name}</p>
                </a>

                <ul className="brsr-other-products-content">
                  {product?.contents.map((content) => {
                    return <li key={content}>{content}</li>;
                  })}
                </ul>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default BrsrOtherProducts;
