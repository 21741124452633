import React, { useEffect, useState } from "react";
import BrsrLandingPage from "../components/BrsrLandingPage";
import resourceValues from "../../../shared/constants/resourceValues.js";
import assets from "../../../shared/constants/assetVariables";

function BrsrLandingPageContainer() {
  const corePrinciplesList = [
    {
      id: "1",
      name: "Ethics, Transparency & Accountability",
      contents: "Maintaining integrity in all business operations.",
      productLogo: require("../../../assets/brsrLandingPage/corePrincipleIcons/1.svg"),
    },
    {
      id: "2",
      name: "Product Life Cycle Sustainability",
      contents: "Ensuring sustainable production processes",
      productLogo: require("../../../assets/brsrLandingPage/corePrincipleIcons/2.svg"),
    },
    {
      id: "3",
      name: "Employee Well-being",
      contents: "Fostering a safe and healthy work environment.",
      productLogo: require("../../../assets/brsrLandingPage/corePrincipleIcons/3.svg"),
    },
    {
      id: "4",
      name: "Stakeholder Engagement",
      contents: "Building and maintaining trust with stakeholders",
      productLogo: require("../../../assets/brsrLandingPage/corePrincipleIcons/4.svg"),
    },
    {
      id: "5",
      name: "Human Rights",
      contents: "Respecting and promoting human rights across operations.",
      productLogo: require("../../../assets/brsrLandingPage/corePrincipleIcons/5.svg"),
    },
    {
      id: "6",
      name: "Environment",
      contents: "Committing to environmental protection and sustainability.",
      productLogo: require("../../../assets/brsrLandingPage/corePrincipleIcons/6.svg"),
    },
    {
      id: "7",
      name: "Public and Regulatory Policy",
      contents: "Engaging in policy advocacy for sustainable development",
      productLogo: require("../../../assets/brsrLandingPage/corePrincipleIcons/7.svg"),
    },
    {
      id: "8",
      name: "Inclusive Growth",
      contents: "Supporting inclusive and equitable growth.",
      productLogo: require("../../../assets/brsrLandingPage/corePrincipleIcons/8.svg"),
    },
    {
      id: "9",
      name: "Customer Value",
      contents:
        "Providing products and services that create value for customers responsibly.",
      productLogo: require("../../../assets/brsrLandingPage/corePrincipleIcons/9.svg"),
    },
  ];

  const reportingList = [
    {
      id: "1",
      name: "Smart Emission Calculation",
      contents: [
        "Advanced algorithms for precise carbon and greenhouse gas emissions tracking.",
        "Comprehensive Scope 1, 2, and 3 calculations.",
        "Materiality Assessment",
      ],
      productLogo: require("../../../assets/brsrLandingPage/simplifyingBrsrReporting/1.svg"),
    },
    {
      id: "2",
      name: "Global Reporting Standard Alignment",
      contents: [
        "Seamless integration with BRSR and other global standards like TCFD, and CDP.",
        "Effortlessly customize reports with flexible frameworks",
      ],
      productLogo: require("../../../assets/brsrLandingPage/simplifyingBrsrReporting/2.svg"),
    },
    {
      id: "3",
      name: "Interactive Dashboards",
      contents: [
        "Real-time visualization of environmental impacts.",
        "Intensity-based performance comparisons for actionable insights.",
      ],
      productLogo: require("../../../assets/brsrLandingPage/simplifyingBrsrReporting/3.svg"),
    },
    {
      id: "4",
      name: "AI & ML Driven Analytics",
      contents: [
        "Benchmark performance with industry peers.",
        "Predict future trends with advanced analytics.",
      ],
      productLogo: require("../../../assets/brsrLandingPage/simplifyingBrsrReporting/4.svg"),
    },
    {
      id: "5",
      name: "Enterprise-level Collaboration & Dynamic APIs",
      contents: [
        "Robust APIs for multi-technology integration.",
        "Sitelevel performance comparisons & custom integrations.",
        "Materiality Assessment",
      ],
      productLogo: require("../../../assets/brsrLandingPage/simplifyingBrsrReporting/5.svg"),
    },
    {
      id: "6",
      name: "Audit Management & Assurance",
      contents: [
        "Streamline audit workflows with automated notifications.",
        "Comprehensive, tailored audit reports.",
      ],
      productLogo: require("../../../assets/brsrLandingPage/simplifyingBrsrReporting/6.svg"),
    },
  ];

  const otherProductsList = [
    {
      id: "1",
      name: "Supply Chain Assessment",
      contents: [
        "Supplier ESG risk assessments & due diligence",
        "Dynamic ESG KPIs",
        "Custom survey option & bespoke scoring framework",
        "Dynamic dashboard with Supplier ESG insights",
      ],
      productLogo: require("../../../assets/brsrLandingPage/otherProducts/1.svg"),
      link: "/solutions/supplier-assessment",
    },
    {
      id: "2",
      name: "Double Materiality Assessment",
      contents: [
        "Conduct detailed materiality assessments with stakeholder input.",
        "Align with SASB, IIRC, and custom requirements.",
        "Evaluate responses to identify prioritized topics.",
        "Aggregate results by material topic.",
        "Generate a materiality matrix with a heat map of impact and importance.",
      ],
      productLogo: require("../../../assets/brsrLandingPage/otherProducts/2.svg"),
      link: "/solutions/supplier-assessment",
    },
    {
      id: "3",
      name: "Net-Zero",
      contents: [
        "Target setting, monitoring and reporting",
        "Climate change dashboard",
        "Net Zero pathway & SBTi pathway",
        "Carbon offset projects - funding and purchasing",
      ],
      productLogo: require("../../../assets/brsrLandingPage/otherProducts/3.svg"),
      link: "/solutions/netzero",
    },
    {
      id: "4",
      name: "Portfolio Management for Fund & Lenders",
      contents: [
        "Supplier ESG risk assessments & due diligence",
        "Dynamic ESG KPIs",
        "Custom survey option & bespoke scoring framework",
        "Dynamic dashboard with Supplier ESG insights",
      ],
      productLogo: require("../../../assets/brsrLandingPage/otherProducts/4.svg"),
      link: "/solutions/portfolio-assessment",
    },
  ];

  const [clientDetails] = useState([
    {
      clientName: "TATA Power",
      clientLogo: assets.images.tatapower,
      ClientDesc:
        "At Tata Power, our commitment to sustainable practices and adherence to ESG principles is unwavering. With the ESG Dashboard provided by Updapt, we have a powerful tool at our fingertips, enabling us to monitor and visualize key environmental metrics in real-time. From tracking our carbon footprint to managing energy consumption and automating GHG accounting, this dashboard provides invaluable insights that drive our sustainability efforts forward. \n One of the standout features of Updapt's dashboard is its Peer Analysis capability, which allows us to benchmark our ESG performance against industry peers. This not only helps us identify areas for improvement but also sheds light on best practices to emulate. The interactive user interface and collaborative features foster enhanced communication and teamwork within our organization, ensuring everyone is aligned towards our sustainability goals. \n Moreover, Updapt seamlessly integrates with our existing processes and tools, streamlining our sustainability initiatives and maximizing efficiency. With actionable insights and peer comparisons at our disposal, we are empowered to make informed decisions that propel Tata Power towards a greener and more sustainable future.",
      clientIcon: assets.images.tataPowerTestimonialIcon,
      authorName: "Dr. Ivaturi Nandikeswara Rao",
      authorDesc: "Head Environment & Climate Change at TATA Power",
    },
    {
      clientName: "Apollo Hospitals Group",
      clientLogo: assets.images.apollo,
      ClientDesc:
        "Integrating Updapt's digital ESG tool at Apollo Hospitals has had a significant impact on our sustainability practices. We have created an extensive emissions inventory that has helped put targets to reduce our carbon footprint. The tool's user-friendly features have made it easier for us to track, monitor, and manage our ESG Data, reporting according to global standard frameworks and audit management. Features like Peer Analysis, and Predictive Analysis tools have been useful in providing practical insights to track our progress, compare ourselves to industry peers, and prepare for future challenges.",
      clientIcon: assets.images.apolloHospitalsTestimonialIcon,
      authorName: "Dr. Karan Thakur",
      authorDesc: "Group Lead - Sustainability & ESG at Apollo Hospitals Group",
    },
    {
      clientName: "Cipla",
      clientLogo: assets.images.cipla,
      ClientDesc:
        "Digital ESG Solution of Updapt is enabling Cipla in achieving Sustainability Goals to be a Carbon Neutral, Water Neutral and Zero Waste to Landfill company. The solution is robust and agile wherein we are able to manage our ESG data lifecycle for our global operations and sites in a seamless manner along with automated GHG accounting. The dashboards are sharp for the Management team to quickly comprehend and monitor key ESG performance metrics along with auditable and complete Sustainability reports well aligned to standards such as GRI, BRSR, etc.",
      clientIcon: assets.images.profile,
      authorName: "Sharad Kalghatgi",
      authorDesc:
        "Global Vice President & Group Head EHS & Sustainability, Cipla",
    },
    {
      clientName: "Licious",
      clientLogo: assets.images.licious,
      ClientDesc:
        "SaaS based ESG solution of Updapt is being used by Licious to manage the ESG operations efficiently in terms of data management across our units, carbon footprint accounting and performance monitoring towards our net-zero goals. The tool is easy to use by our team members across various sites supported by a quick responsive team of Updapt for any handholding. ESG performance reports and MIS generated through the digital solution of Updapt supports the Sustainability team in internal management discussions and also our engagements with other stakeholders such as Investors, Board, etc.",
      clientIcon: assets.images.img2,
      authorName: "Rajesh Kumaramenon",
      authorDesc: "Chief Quality and Sustainability Officer, Licious",
    },
    {
      clientName: "Pricol ",
      clientLogo: assets.images.pricol,
      ClientDesc:
        "Updapt ESG solution is helping Pricol calculate the organisations overall carbon footprint in a fully digitised and seamless manner along with advanced analytics and interactive dashboards. The solutions easy to use interface & strong computational capability have helped manage our ESG metrics effectively while also facilitating Sustainability reporting in alignment with BRSR & GRI standards.",
      clientIcon: assets.images.pricolIcon,
      authorName: "T.G Thamizhanban",
      authorDesc: "Company Secretary, Pricol",
    },
  ]);
  const [blogsData] = useState(resourceValues);
  const homeBlogsData = blogsData.slice(blogsData.length - 4, blogsData.length);
  const [index, setIndex] = useState(0);
  const [characterCount, setCharacterCount] = useState(
    clientDetails[0].ClientDesc.length
  );
  const [readMoreBool, setReadMoreBool] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleNextButton = () => {
    setReadMoreBool(false);
    if (index < clientDetails.length - 1) {
      setIndex(1 + index);
      setCharacterCount(clientDetails[1 + index].ClientDesc.length);
    }
  };
  const handleBackButton = () => {
    setReadMoreBool(false);
    if (index > 0) {
      setIndex(index - 1);
      setCharacterCount(clientDetails[index - 1].ClientDesc.length);
    }
  };
  const handleReadMore = () => {
    setReadMoreBool(!readMoreBool);
  };

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <BrsrLandingPage
      corePrinciplesList={corePrinciplesList}
      resourceBlogs={homeBlogsData}
      reportingList={reportingList}
      otherProductsList={otherProductsList}
      handleNextButton={handleNextButton}
      clientDetails={clientDetails}
      index={index}
      handleBackButton={handleBackButton}
      characterCount={characterCount}
      handleReadMore={handleReadMore}
      readMoreBool={readMoreBool}
      windowWidth={windowWidth}
    />
  );
}

export default BrsrLandingPageContainer;
