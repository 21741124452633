import React from "react";
import { NavLink } from "react-router-dom";
import assets from "../../../shared/constants/assetVariables";

function BrsrSecondDemoBanner() {
  return (
    <section>
      <div className="brsr-second-demo-banner-outer-container">
        <div
          className="brsr-second-demo-banner-image-container"
          data-aos="fade-right"
          data-aos-delay="100"
          data-aos-duration="1000"
        >
          <img
            src={require("../../../assets/brsrLandingPage/demo2.svg")}
            alt="demo"
          />
        </div>
        <div
          className="brsr-second-demo-banner-heading-container"
          data-aos="fade-left"
          data-aos-delay="100"
          data-aos-duration="1000"
        >
          <p className="brsr-second-demo-banner-heading">
            Ready to Enhance Your ESG Performance?{" "}
          </p>
          <p className="brsr-second-demo-banner-subheading">
            Claim your free demo today and see how Updapt can transform your ESG
            reporting and compliance!
          </p>
          <NavLink to="/demo" className="brsr-second-demo-banner-demo-btn">
            Book a demo Now{" "}
            <img
              src={assets.icons.rightArrowIconWhite}
              alt="rightArrowIconWhite"
              className="right-arrow-icon"
            />
          </NavLink>{" "}
        </div>
        <img
          className="brsr-second-demo-Bg"
          src={require("../../../assets/brsrLandingPage/secondDemoBg.svg")}
          alt="performanceManagementBg"
        />
      </div>
    </section>
  );
}

export default BrsrSecondDemoBanner;
