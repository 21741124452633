import React from "react";

function BrsrReporting({ reportingList }) {
  return (
    <div className="brsr-reporting-outer-container">
      <div className="brsr-reporting-heading-cover">
        <p className="brsr-reporting-heading">
          Updapt's ESG Software Simplifying BRSR Reporting
        </p>
      </div>
      <p className="brsr-reporting-sub-heading">
        UpdaptESG (
        <a href="/solutions/esg-data-management">ESG Data Management</a>,
        Reporting & <a href="/solutions/esg-analytics">Analysis Software</a>)
        Cutting-edge digital ESG reporting software is designed to empower
        companies in their sustainability journey. Our comprehensive solution
        simplifies the collection, tracking, analysis, and consolidation of ESG
        data with precision and ease. With our extensive expertise and
        experience, we provide a scalable and tailored solution for mid and
        large organizations to address their diverse{" "}
        <a href="/resource/blogs">ESG needs</a>.
      </p>
      <div className="brsr-reporting-card-container">
        {reportingList?.map((product) => {
          return (
            <div className="brsr-reporting-card" key={product.id}>
              <p className="brsr-reporting-logo">
                <img src={product?.productLogo} alt={product.name} />
              </p>
              <div className="brsr-reporting-content-cover">
                <p className="brsr-reporting-name">{product?.name}</p>

                <ul className="brsr-reporting-content">
                  {product?.contents.map((content) => {
                    return <li key={content}>{content}</li>;
                  })}
                </ul>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default BrsrReporting;
