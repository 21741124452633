import React from "react";
import "../SCSS/styles.css";
import { NavLink } from "react-router-dom";

export default function BrsrBanner() {
  return (
    <section className="brsr-banner-main-container">
      <div className="brsr-banner-outer-container">
        <img
          className="brsr-banner-logo"
          src={require("../../../assets/brsrLandingPage/updaptLogo.svg")}
          alt="logo"
        />
        <div className="brsr-banner-heading-container">
          <p className="brsr-banner-heading">Empower Your ESG</p>
          <p className="brsr-banner-heading">Strategies with</p>
          <p className="brsr-banner-heading">
            Updapt's BRSR Reporting Software
          </p>
          <p className="brsr-banner-subheading">
            As the sustainability landscape evolves, businesses face mounting
            pressure to report and enhance their ESG (Environmental, Social, and
            Governance) performance. Updapt offers cutting-edge{" "}
            <a href="/solutions/esg-reporting">ESG reporting software</a>{" "}
            tailored for Indian companies, focusing on Business Responsibility
            and Sustainability Reporting (BRSR).
          </p>
          <NavLink to="/demo" className="brsr-banner-demo-btn">
            Book a demo now
          </NavLink>
        </div>
      </div>{" "}
      <div className="brsr-banner-transition-images-cover">
        <div className="brsr-banner-transition-images-section-1">
          <img
            src={require("../../../assets/brsrLandingPage/brsrBannerImage1.svg")}
            alt="brsrBannerImage1"
            className="brsr-banner-image-1"
            data-aos="fade-down-right"
            data-aos-delay="100"
            data-aos-duration="700"
          />

          <img
            src={require("../../../assets/brsrLandingPage/brsrBannerImage2.svg")}
            alt="brsrBannerImage2"
            className="brsr-banner-image-2"
            data-aos="fade-down-left"
            data-aos-delay="100"
            data-aos-duration="700"
          />
        </div>
        <div className="brsr-banner-transition-images-section-2">
          {" "}
          <img
            src={require("../../../assets/brsrLandingPage/brsrBannerImage3.svg")}
            alt="brsrBannerImage3"
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="700"
          />
        </div>
      </div>
      <img
        src={require("../../../assets/brsrLandingPage/mainBannerCurvedSection.svg")}
        alt="brsr-banner-curved-section"
        className="brsr-banner-curved-section"
      />
    </section>
  );
}
