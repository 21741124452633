import React from "react";
import BrsrPerformanceDesktopSvg from "./BrsrPerformanceDesktopSvg";
import BrsrPerformanceMobileSvg from "./BrsrPerformanceMobileSvg";

function BrsrPerformanceManagement({ windowWidth }) {
  return (
    <div className="brsr-performance-outer-container">
      <div
        className="brsr-performance-heading-cover"
        data-aos="fade-up"
        data-aos-delay="500"
        data-aos-duration="1000"
      >
        <p className="brsr-performance-heading">
          Challenges faced by Businesses in India in their ESG Performance
          Management
        </p>
      </div>
      <div className="brsr-performance-image-cover">
        {windowWidth > 768 ? (
          <BrsrPerformanceDesktopSvg />
        ) : (
          <BrsrPerformanceMobileSvg windowWidth={windowWidth} />
        )}
      </div>
      <div className="brsr-performance-card-container"></div>
      <img
        className="performance-management-Bg"
        src={require("../../../assets/brsrLandingPage/performanceManagementBg.svg")}
        alt="performanceManagementBg"
      />
    </div>
  );
}

export default BrsrPerformanceManagement;
