import React from "react";
import "../SCSS/styles.css";

function BrsrSecondaryDescription() {
  return (
    <div className="brsr-description-outer-container">
      <p className="brsr-description-heading">
        Why BRSR Reporting is Essential{" "}
      </p>
      <p className="brsr-description-content">
        BRSR reporting is a critical aspect of corporate governance and
        sustainability in India, mandated by SEBI for top 1000 listed companies
        by market capitalization. It encompasses comprehensive disclosure on a
        company's environmental, social, and governance performance,{" "}
        <a href="/resource/media">fostering transparency and accountability</a>.
      </p>
    </div>
  );
}

export default BrsrSecondaryDescription;
