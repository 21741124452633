import React from "react";
import { NavLink } from "react-router-dom";

function BrsrFreeTrial() {
  return (
    <section>
      <div className="brsr-free-trial-banner-outer-container">
        <div className="brsr-free-trial-banner-heading-container">
          <p className="brsr-free-trial-banner-heading">
            Claim your free trail now !{" "}
          </p>
          <p className="brsr-second-demo-banner-subheading">
            Transform your sustainability journey with precise, efficient, and
            comprehensive ESG reporting, data management and Analytics tool.
          </p>
          <NavLink
            to="/free-trial"
            className="brsr-free-trial-banner-free-trial-btn"
          >
            Claim your free trial
          </NavLink>
        </div>
        <div className="brsr-free-trial-banner-image-container">
          <img
            src={require("../../../assets/brsrLandingPage/freeTrial.svg")}
            alt="freeTrial"
          />
        </div>
      </div>
    </section>
  );
}

export default BrsrFreeTrial;
