import React from "react";

function BrsrCorePrinciples({ corePrinciplesList }) {
  return (
    <div className="brsr-core-principles-outer-container">
      <div
        className="brsr-core-principles-inner-container"
        data-aos="fade-up"
        data-aos-delay="100"
        data-aos-duration="1000"
      ></div>
      <p className="brsr-core-principles-heading">Key BRSR Core Principles</p>
      <div className="brsr-core-principles-card-container">
        {corePrinciplesList?.map((product) => {
          return (
            <div className="brsr-core-principles-card" key={product.id}>
              <p className="brsr-core-principles-logo">
                <img src={product?.productLogo} alt={product.name} />
              </p>
              <p className="brsr-core-principles-name">{product?.name}</p>

              <p className="brsr-core-principles-content">
                {product?.contents}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default BrsrCorePrinciples;
