import React, { useEffect } from "react";
import "./App.scss";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Route, withRouter, Switch, useLocation } from "react-router-dom";
import Navbar from "./features/Navbar";
import Footer from "./features/Footer";
import Landing from "./features/Landing";
import Solutions from "./features/Solutions";
import AboutUs from "./features/AboutUs";
import ContactUs from "./features/ContactUs";
import FreeTrial from "./features/FreeTrial";
import Privacy from "./features/Privacy";
import CookiePolicy from "./features/CookiePolicy";
import Resource from "./features/Resource";
import Demo from "./features/Demo";
import Thankyou from "./features/Thankyou";
import FreshChat from "react-freshchat";
import AOS from "aos";
import Company from "./features/Company";
import Faq from "./features/FAQ";
import NewLandingPage from "./features/NewLandingPage";
import BrsrLandingPage from "./features/BrsrLandingPage";
function App() {
  useEffect(() => {
    AOS.init();
  }, []);
  let isTrue = "false";

  const location = useLocation();
  const path = location.pathname.split("/")[1];
  return (
    <>
      <div className="App">
        {
          //false added to remove freshchat until necessary
          isTrue === "true" && (
            <FreshChat
              token={"ef53ae91-778a-4984-a29b-b6262e514446"}
              siteId="website"
              tags={["Contact"]}
            />
          )
        }
        {path !== "uae-esg-reporting-software" &&
          path !== "brsr-reporting-software" && <Navbar></Navbar>}
        <Switch>
          <Route
            path="/"
            exact={true}
            render={() => <Landing></Landing>}
          ></Route>
          <Route
            path="/solutions"
            render={() => <Solutions></Solutions>}
          ></Route>

          <Route path="/company" render={() => <Company />}></Route>

          <Route path="/about" render={() => <AboutUs></AboutUs>}></Route>
          <Route path="/contact" render={() => <ContactUs></ContactUs>}></Route>
          <Route
            path="/uae-esg-reporting-software"
            render={() => <NewLandingPage />}
          ></Route>
          <Route
            path="/brsr-reporting-software"
            render={() => <BrsrLandingPage />}
          ></Route>

          <Route
            path="/free-trial"
            render={() => <FreeTrial></FreeTrial>}
          ></Route>
          <Route path="/privacy" render={() => <Privacy></Privacy>}></Route>
          <Route
            path="/contact-success"
            render={() => <Thankyou></Thankyou>}
          ></Route>

          <Route
            path="/company-success"
            render={() => <Thankyou></Thankyou>}
          ></Route>
          <Route
            path="/demo-success"
            render={() => <Thankyou></Thankyou>}
          ></Route>
          <Route
            path="/free-trial-success"
            render={() => <Thankyou></Thankyou>}
          ></Route>
          <Route
            path="/career-success"
            render={() => <Thankyou></Thankyou>}
          ></Route>
          <Route
            path="/cookiepolicy"
            render={() => <CookiePolicy></CookiePolicy>}
          ></Route>
          <Route path="/resource" render={() => <Resource></Resource>}></Route>
          <Route path="/demo" render={() => <Demo></Demo>}></Route>
          <Route path="/faqs" render={() => <Faq />}></Route>
          <Route render={() => <Landing></Landing>}></Route>
        </Switch>
        <Footer></Footer>
      </div>
    </>
  );
}

export default withRouter(App);
