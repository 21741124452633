import React from "react";
import BrsrBanner from "./BrsrBanner";
import BrsrSecondaryDescription from "./BrsrSecondaryDescription";
import BrsrCorePrinciples from "./BrsrCorePrinciples";
import BrsrDemoBanner from "./BrsrDemoBanner";
import BrsrSecondDemoBanner from "./BrsrSecondDemoBanner";
import BrsrReporting from "./BrsrReporting";
import BrsrOtherProducts from "./BrsrOtherProducts";
import BrsrDigitalTools from "./BrsrDigitalTools";
import BrsrFreeTrial from "./BrsrFreeTrial";
import BrsrResources from "./BrsrResources";
import BrsrTestimonials from "./BrsrTestimonials";
import Demo from "../../Demo";
import BrsrPerformanceManagement from "./BrsrPerformanceManagement";
import { MetaTags } from "react-meta-tags";

function BrsrLandingPage({
  corePrinciplesList,
  resourceBlogs,
  reportingList,
  otherProductsList,
  handleNextButton,
  clientDetails,
  index,
  handleBackButton,
  characterCount,
  handleReadMore,
  readMoreBool,
  windowWidth,
}) {
  return (
    <div className="brsr-landing-page-container">
      {" "}
      <MetaTags>
        <title>
          Sustainability and ESG Reporting Software with BRSR Framework
        </title>
        <meta
          name="description"
          content="Updapt ESG Reporting Software provides industry based reporting solutions with data management and it has various frameworks such as CSRD, ESRS, TCFD, CDP, and BRSR."
        />
        <meta
          property="og:title"
          content="Sustainability and ESG Reporting Software with BRSR Framework."
        />
        <meta
          property="og:image"
          content="%PUBLIC_URL%/static/media/banner.jpg"
        />
      </MetaTags>
      <BrsrBanner />
      <BrsrSecondaryDescription />
      <BrsrCorePrinciples corePrinciplesList={corePrinciplesList} />
      <BrsrDemoBanner />
      <BrsrPerformanceManagement windowWidth={windowWidth} />
      <BrsrSecondDemoBanner />
      <BrsrReporting reportingList={reportingList} />
      <BrsrOtherProducts otherProductsList={otherProductsList} />
      <BrsrDigitalTools />
      <BrsrFreeTrial />
      <BrsrResources resourceBlogs={resourceBlogs} />
      <BrsrTestimonials
        handleNextButton={handleNextButton}
        clientDetails={clientDetails}
        index={index}
        handleBackButton={handleBackButton}
        characterCount={characterCount}
        handleReadMore={handleReadMore}
        readMoreBool={readMoreBool}
      />
      <Demo />
    </div>
  );
}

export default BrsrLandingPage;
