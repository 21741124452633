import React from "react";
import { useHistory } from "react-router-dom";

function Products({ productsList }) {
  const history = useHistory();
  return (
    <div className="products-outer-container">
      <p className="product-heading">Updapt Products</p>
      <div className="product-card-container">
        {productsList?.map((product) => {
          return (
            <div
              className="product-card"
              key={product.id}
              style={{
                backgroundColor: product?.bgColor,
                boxShadow: `0px -14px 0px 0px ${product?.logoColor}`,
              }}
              onClick={() => history.push(product?.productUrl)}
            >
              <p className="product-name">{product?.name}</p>
              <p className="product-description">{product?.description}</p>
              <p
                className="product-logo"
                style={{ backgroundColor: product?.logoColor }}
              >
                <img src={product?.productLogo} alt={product.name} />
              </p>
              <p className="product-content">{product?.contents}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Products;
