import React from "react";

function BrsrDigitalTools() {
  return (
    <div className="brsr-digital-products-outer-container">
      <div className="brsr-digital-products-heading-cover">
        <p className="brsr-digital-products-heading">
          Empower your ESG strategies with robust compliance & High ROI through
          Updapt digital ESG tools.
        </p>
      </div>

      <div className="brsr-digital-products-card-container">
        <div
          className="brsr-digital-products-card-1 card-mobile"
          data-aos="fade-right"
          data-aos-delay="100"
          data-aos-duration="1000"
        >
          <p className="brsr-digital-products-card-title-cover">
            Accurate & Complete ESG Data
          </p>
          <p className="brsr-digital-products-card-value">100%</p>
        </div>
        <div
          className="brsr-digital-products-card-2 card-mobile"
          data-aos="fade-right"
          data-aos-delay="100"
          data-aos-duration="1000"
        >
          <p className="brsr-digital-products-card-title-cover">
            Carbon intensity reduction{" "}
          </p>
          <p className="brsr-digital-products-card-value">20%</p>
        </div>
        <div
          className="brsr-digital-products-card-3 card-mobile"
          data-aos="fade-left"
          data-aos-delay="100"
          data-aos-duration="1000"
        >
          <p
            className="brsr-digital-products-card-title-cover"
            data-aos="fade-down"
            data-aos-delay="500"
            data-aos-duration="1000"
          >
            Companies stated progress towards NetZero & ESG Rating
          </p>
          <p
            className="brsr-digital-products-card-value"
            data-aos="fade-down"
            data-aos-delay="500"
            data-aos-duration="1000"
          >
            78%
          </p>
        </div>
        <div
          className="brsr-digital-products-card-4 card-mobile"
          data-aos="fade-left"
          data-aos-delay="100"
          data-aos-duration="1000"
        >
          <p className="brsr-digital-products-card-title-cover">
            Hours Saved per year
          </p>
          <p className="brsr-digital-products-card-value">7000+</p>
        </div>{" "}
        <div
          className="brsr-digital-products-card-5 card-mobile"
          data-aos="fade-up"
          data-aos-delay="100"
          data-aos-duration="1000"
        >
          <p className="brsr-digital-products-card-title-cover">
            Businesses have Mitigate ESG Risks
          </p>
          <p className="brsr-digital-products-card-value">84%</p>
        </div>
      </div>
    </div>
  );
}

export default BrsrDigitalTools;
