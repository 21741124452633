import React from "react";
import DemoForm from "./Form";
import DemoFeature from "./Feature";
import assets from "../../../shared/constants/assetVariables";
import Faq from "../../FAQ";
import { useLocation } from "react-router-dom";
import NewLandingPageFeature from "./NewLandingPageFeature";

function FormSection({
  mobile,
  setMobile,
  handleTextOnChange,
  handleSendRequest,
  formObj,
  setSelectedOption,
  selectedOption,
  options,
  customStyles,
}) {
  const location = useLocation();
  const path = location.pathname.split("/").pop();
  return (
    <>
      {path !== "uae-esg-reporting-software" ? (
        <section className="demo-form-section">
          <div className="container">
            <div className="row">
              <DemoFeature />

              <DemoForm
                formObj={formObj}
                handleTextOnChange={handleTextOnChange}
                handleSendRequest={handleSendRequest}
                mobile={mobile}
                setMobile={setMobile}
                options={options}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                customStyles={customStyles}
              />
            </div>
          </div>
          {path !== "brsr-reporting-software" && <Faq />}
          <div className="background-image">
            <span className="dot-shape">
              {" "}
              <img src={assets.images.homeBannerImageDot} alt="updapt" />
            </span>
            <span className="back-image">
              {" "}
              <img src={assets.images.contactBottomImage} alt="updapt" />
            </span>
          </div>
        </section>
      ) : (
        <section className="new-landing-page-form-container">
          <div className="new-landing-page-row">
            <NewLandingPageFeature />

            <DemoForm
              formObj={formObj}
              handleTextOnChange={handleTextOnChange}
              handleSendRequest={handleSendRequest}
              mobile={mobile}
              setMobile={setMobile}
              options={options}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              customStyles={customStyles}
            />
          </div>
        </section>
      )}
    </>
  );
}

export default FormSection;
